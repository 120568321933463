<template>
  <el-dialog
      v-model="isShow"
      title="编辑设备工单"
      width="30%"
      :before-close="handleClose"
  >
    <el-form ref="formRef" :inline="false" :model="formState" :rules="rules">
      <el-form-item label="设备编号" prop="deviceNo" label-width="100">
        <el-select v-model="formState.deviceNo" style="width:100%" filterable clearable
                   placeholder="请选择设备编号">
          <el-option
              v-for="deviceNo in deviceNoOption"
              :key="deviceNo"
              :label="deviceNo"
              :value="deviceNo"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="工单名称" prop="name" label-width="100">
        <el-input maxlength="100" v-model="formState.name" placeholder="请输入工单名称"/>
      </el-form-item>
      <el-form-item label="工单负责人" prop="userId" label-width="100">
        <el-select v-model="formState.userId" style="width:100%" filterable clearable
                   placeholder="请选择工单负责人" @change="changeUser">
          <el-option
              v-for="item in userListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="故障现象" label-width="100">
        <el-button type="primary" round @click="showSelectFaultModal">新增故障</el-button>
      </el-form-item>
      <el-form-item v-for="(fault, index) in selectedFault" :label="`现象${index+1}：`"
                    label-width="100" :prop="`fault${index}`">
        <span>{{ selectDictLabel(dict.type.work_order_fault_phenomenon_type, fault) }}</span>
        <el-input maxlength="150" v-model="formState.workOrderFaultDescriptionList[index].description" placeholder="请描述问题"/>
        <div>
          <span>支持png/jpg，10MB以内</span>
          <image-upload
              :value="formState.workOrderFaultDescriptionList[index].faultImageList"
              :limit="4" :is-show-tip="false"
              @update:file-list="handleUploadFile($event, index)"
              :file-type="fileTypes"
              :file-size="10"
          ></image-upload>
        </div>
      </el-form-item>
      <el-checkbox label="草稿" size="large" :checked="true" @change="changeCheckBox"/>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :disabled="submitting">
              确定
            </el-button>
          </span>
    </template>
  </el-dialog>

  <select-fault
      v-if="isShowSelectFaultModal"
      v-model:visible="isShowSelectFaultModal"
      :selected-fault="selectedFault"
      @completed="handleFault"
  />
</template>

<script>
import {isResOK} from '@/api/response'
import ImageUpload from "@/components/ImageUpload/index.vue";
import {selectDictValue} from "@/utils/ruoyi";
import { getDeviceNoOption} from "@/api/device_location";
import SelectFault from "@/view/work_order/SelectFault.vue";
import {getWorkOrder, updateWorkOrder} from "@/api/work_order";
import {findOperationUserList} from "@/api/userList";

let baseURL = process.env.VUE_APP_BASE_API
export default {
  name: 'EditWorkOrder',
  dicts: ['work_order_status', 'work_order_fault_phenomenon_type'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    id: {
      type: Number,
      required: true,
    },
  },

  components: {
    ImageUpload,
    SelectFault,
  },

  emits: ['update:visible', 'completed'],

  data() {
    return {
      formState: {
        deviceNo: null,
        name: null,
        userId: null,
        userName: null,
        workOrderFaultDescriptionList: [],
        status: null,
      },
      fileTypes: ['png', 'jpeg'],
      submitting: false,
      rules: {
        deviceNo: [
          {required: true, validator: (rules, value, callback) => {
            if (!this.formState.deviceNo || this.formState.deviceNo === '' || this.formState.deviceNo === null) {
              callback('设备编号不能为空')
            }
              return callback();
            }, trigger: "blur"}
        ],
        name: [
          {required: true, message: "工单名称不能为空", trigger: "blur"}
        ],
        userId: [
          {required: true, message: "工单负责人不能为空", trigger: "blur"}
        ],
      },
      deviceNoOption: [],
      selectedFault: [],
      isShowSelectFaultModal: false,
      userListOptions: [],
    }
  },

  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },

  created() {
    this.fetchUserListOptions()
    this.fetchDeviceNoOption()
    this.fetchFormData()
  },

  methods: {
    fetchUserListOptions() {
      findOperationUserList().then(res => {
        this.userListOptions = res
      })
    },

    changeUser(e) {
      this.userListOptions.forEach(user => {
        if (user.id === e) {
          this.formState.userName = user.name
        }
      })
    },

    showSelectFaultModal() {
      this.isShowSelectFaultModal = true
    },

    fetchFormData() {
      this.loading = true
      getWorkOrder(this.id).then((res) => {
        if (isResOK(res)) {
          if (res.data.userId === 0) {
            res.data.userId = undefined
          }
          this.formState = res.data
          this.handleData()
        }
      }).finally(() => {
        this.loading = false
      })
    },

    handleData() {
      const workOrderFaultDescriptionListRule = (rules, value, callback) => {
        const index = (Number)(rules.field.replace(/[^\d]/g, " "))
        if ((this.formState.workOrderFaultDescriptionList[index].description === null ||
                !this.formState.workOrderFaultDescriptionList[index].description ||
                this.formState.workOrderFaultDescriptionList[index].description === '') &&
            (this.formState.workOrderFaultDescriptionList[index].faultImageList === null ||
                !this.formState.workOrderFaultDescriptionList[index].faultImageList ||
                this.formState.workOrderFaultDescriptionList[index].faultImageList.length === 0
            )) {
          return callback('输入描述或上传至少一张照片')
        }
        return callback()
      }
      const selectedFault = []
      this.formState.workOrderFaultDescriptionList.forEach((fault, index) => {
        fault.faultImageList.forEach((file, index) => {
          file.url = baseURL + file.storageBlob.newFilename
        })
        selectedFault.push(fault.faultPhenomenonType)
        this.rules[`fault${index}`] = [
          {required: true, validator: workOrderFaultDescriptionListRule, trigger: "blur"}
        ]
      })
      this.selectedFault = selectedFault
    },

    fetchDeviceNoOption() {
      getDeviceNoOption().then(res => {
        if (isResOK(res)) {
          this.deviceNoOption = res.data
        }
      })
    },

    handleFault(value) {
      const oldFaults = Array.from(this.formState.workOrderFaultDescriptionList)
      const workOrderFaultDescriptionListRule = (rules, value, callback) => {
        const index = (Number)(rules.field.replace(/[^\d]/g, " "))
        if ((this.formState.workOrderFaultDescriptionList[index].description === null ||
                !this.formState.workOrderFaultDescriptionList[index].description ||
                this.formState.workOrderFaultDescriptionList[index].description === '') &&
            (this.formState.workOrderFaultDescriptionList[index].faultImageList === null ||
                !this.formState.workOrderFaultDescriptionList[index].faultImageList ||
                this.formState.workOrderFaultDescriptionList[index].faultImageList.length === 0
            )) {
          return callback('输入描述或上传至少一张照片')
        }
        return callback()
      }
      this.selectedFault = value
      this.formState.workOrderFaultDescriptionList.length = value.length
      value.forEach((fault, index) => {
        this.formState.workOrderFaultDescriptionList[index] = {
          description: null,
          faultImageList: null,
          faultPhenomenonType: fault
        }
        oldFaults.forEach(oldFault => {
          if (fault === oldFault.faultPhenomenonType) {
            this.formState.workOrderFaultDescriptionList[index] = oldFault
          }
        })
        this.rules[`fault${index}`] = [
          {required: true, validator: workOrderFaultDescriptionListRule, trigger: "blur"}
        ]
      })
    },

    handleUploadFile(fileList, index) {
      this.formState.workOrderFaultDescriptionList[index].faultImageList = fileList
    },

    changeCheckBox(e) {
      if (e) {
        this.formState.status = selectDictValue(this.dict.type.work_order_status, 'Draft')
      } else {
        this.formState.status = selectDictValue(this.dict.type.work_order_status, 'NotHandle')
      }
    },

    handleSubmit() {
      if (this.submitting) {
        this.$message({
          message: '已经提交，无需重复提交',
          type: 'warning',
        })
        return
      }

      if (this.formState.workOrderFaultDescriptionList.length === 0) {
        this.$message({
          message: '至少增加一个现象',
          type: 'warning',
        })
        return
      }

      this.submitting = true
      this.$refs.formRef.validate((valid, fields) => {
        if (valid) {
          this.formState.id = this.id
          let operatorId = ''
          let operatorName = ''
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          if (userInfo !== undefined){
            operatorId = userInfo.id
            operatorName = userInfo.name
          }
          updateWorkOrder(Object.assign({}, this.formState, Object.assign({}, this.formState,
              { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName }))).then((res) => {
            if (isResOK(res)) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
          }).finally(() => {
            this.submitting = false
          })
        }
      }).catch(() => {
        this.submitting = false
        // validate failed
      })
    },

    handleClose() {
      this.isShow = false
    }
  },
}
</script>

<style scoped lang="less">
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
}

::v-deep .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>
